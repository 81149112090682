.fc-theme-standard td, .fc-theme-standard th{
	border: inherit !important;
}
.fc .fc-timegrid-col.fc-day-today{
	background: inherit;
}
.fc-timegrid-event .fc-event-time{
	display: none;
}
.fc-v-event .fc-event-title{
	text-align: center;
}
.event-green{
	background: green;
    border: 1px solid green;
}
.event-yellow{
	background: yellow;
    border: 1px solid yellow;
}
.event-yellow .fc-event-main{
	  color: #000;
}
.event-yellow1{
	background: #F8D7A7;
    border: 1px solid #F8D7A7;
}
.event-yellow1 .fc-event-main{
	  color: #000;
}
.event-yellow2{
	background: #FBA424;
    border: 1px solid #FBA424;
}
.event-yellow2 .fc-event-main{
	  color: #000;
}
.event-light-gray{
	background: #C8C8C8;
    border: 1px solid #C8C8C8;
}
.event-light-gray .fc-event-main{
	  color: #000;
}
.fc .fc-col-header-cell-cushion{
color: #A0A0A0;
    text-decoration: none;
}
.fc-v-event .fc-event-title{
	    word-break: break-all;
}
.fc-col-header-cell-cushion p{
    color: #000;
    text-align: center;
    
    font-size: 18px;
    
    font-weight: 400;
    line-height: normal;
    
    margin-bottom: 0px;
}
.fc-theme-standard .fc-scrollgrid{
	border: none;
}
.fc .fc-toolbar.fc-header-toolbar{
	margin-bottom: 1em;
}	
.fc .fc-toolbar-title{
	    font-size: 21px;
}
.fc .fc-col-header-cell-cushion{
	text-transform: uppercase;
	font-size: 13px;
}
